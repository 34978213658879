$purple: #00BFA5;
$pink: #fa7c91;
$light-blue: #7a9bd1;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$box-bg: rgb(24, 24, 48);
$app-bg: #0a0a15;
$border-color: rgb(38, 38, 75);
$title-color: $beige-lighter;
$label-color: rgb(116, 121, 144);
$bg-striped: rgba(90,90,174,.1);
$bg-sidebar: rgb(7, 7, 14);
$discrete-border: rgb(34, 34, 68);
$sidebar-border: rgb(24, 24, 47);
$button-bg: rgb(24, 24, 47);
$button-border: rgb(44, 44, 86);
@use "bulma/sass" with (
  $family-primary: '"Roboto Condensed", arial',
  $grey-light: $beige-light,
  $primary: $purple,
  $link: $light-blue,
  $control-border-width: 1px,
  $input-shadow: none,
  $table-background-color: transparent,
  $navbar-background-color: transparent,
);
@import "~bulma/bulma";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css?family=Inter:400,500,600,700&amp;display=swap");

html {
  background-color: $app-bg;
  font-family: Inter, sans-serif;
}

.label {
  color: $beige-light;
}

.App {
  text-align: center;
}

.title {
  font-size: 1.5em;
}

.subtitle {
  font-size: 1.2em;
  font-weight: 800;
  &.is-4 {
    font-size: 1em;
    font-weight: 800;
  }
}

.widget-value {
  color: $title-color;
  font-size: 1.5em;
  font-weight: 800;
}

.widget-label {
  text-transform: uppercase;
  color: $label-color;
  font-size: .75em;
  font-weight: 500;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    max-height: 100%;
    height: 300px;
    width: auto;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.debugger {
  font-family: 'Courier New', Courier, monospace;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ar-nav {
  margin-left: 1.2em;
  margin-top: 2em;
  background-color: $bg-sidebar;
}

.content-main {
  margin-left: 230px;
}

.navbar {
  &.is-dark {
    background-color: transparent;
  }
  .navbar-brand {
    position:fixed;
    min-width: 240px;
    border-right: 1px solid $sidebar-border;
    padding-top: 20px;
    padding-left: 20px;
    .navbar-item {
      color: $beige-light;
        &:hover {
        background-color: $bg-sidebar;
      }
    }
  }
}

.menu-sidebar .svg-inline--fa{
          width:25px;
          font-size: 1.1rem;
          margin-right: .5rem;
        }

.menu-sidebar {
  background-color: $bg-sidebar;
  border-right: 1px solid $sidebar-border;
  position: fixed;
  height: 100%;
  min-width: 240px;
  padding-top:20px;
}

.menu-sidebar a.navbar-item{
    //font-size: .9rem;
    //color: hsl(229, 11%, 51%);
}

.sub-menu-item{
    font-size: .9rem;
}

.menuIcon{
    width:150px;
    display:block;
}

.column.is-2:first-child {
  background-color: #0F172A;
}

.box, .card {
  background-color: $box-bg;
  box-shadow: none;
  border: 1px solid $border-color;
}

.field {
  max-width: 450px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $app-bg;
  color: #F9F9F9;
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.box {
  .table {
    background-color: $box-bg;
    border-collapse: collapse;
    border-spacing:0;
    th {
      font-size: .75rem;
      font-weight: 600!important;
      color: $label-color;
      padding: 1rem .7em!important;
      border-color: transparent;
      text-transform: uppercase;
    }
    &.is-striped {
      tbody {
        tr:not(.is-selected):nth-child(even) {
          background-color: $bg-striped;
        }
      }
    }
  }
}

.button {
  color: $beige-lighter;
  border: 1px solid $button-border;
  &:hover {
    background-color: $bg-striped;
   // border-color: $bg-striped;
  }
}

.has-addons {
  .control {
    //background-color: $button-bg;
    color: $beige-lighter;
    .button {
      border: 1px solid $button-border;
    }
  }
}

.login-wrapper .login-form {
    width: 100%;
    background: $box-bg;
    border-radius: .85rem;
    border: 1px solid var(--card-border-color);
    max-width: 420px;
    padding: 2.5rem;
    margin: 0 auto;
}

.login-wrapper .login-caption {
    max-width: 420px;
    margin: 0 auto 1.5rem;
    text-align: center;
}

.columns.is-vcentered {
    align-items: center;
}

.is-fullheight {
    min-height: 100vh;
}

.title.is-medium {
    font-weight: 500;
}

.title.is-leading {
    margin-top: 3rem !important;
    margin-bottom: .5rem !important;
}

.title.is-2 {
    font-size: 2.5rem;
}

.login-wrapper .login-caption p {
    color: hsl(229, 11%, 51%);
}

.button:not(.is-rounded) {
    border-radius: .75rem;
}

.buttons .button {
    margin-bottom: .5rem;
}

.button.is-fullwidth {
    display: flex;
    width: 100%;
}

.label {
    font-size: .95rem;
    color: hsl(229, 11%, 51%);
    font-weight: 500;
    margin-bottom: 0rem;
}

.login-logo {
    text-align: center;
 }

.login-link {
    text-align: center;
    display:block;
  width:100%;
}

.login-link a {
    font-size: .9rem;
    color: #747990;
    text-align:center;
}

.toolbar {
    height: 40px;
}

.actioncolumnLeft{
  display: flex;
  justify-content: left;
  align-items: center;
}

.actioncolumnRight{
  display: flex;
  justify-content: right;
  align-items: center;
}


.actioncolumn p{

}

.action-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 65px;
    min-width: 65px;
    border: 1px solid #26264b;
    border-radius: 50%;
}

